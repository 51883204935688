import { AimProgressModeType } from '@aimmo/design-system/aim-progress-bar/model/src';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialogConfig } from '@angular/material/dialog';

export const AIM_DIALOG_BACKDROP_CLASS = 'aim-dialog-backdrop';

export const AimDialogMode = {
  alert: 'alert',
  progress: 'progress',
} as const;
export type  AimDialogModeType = keyof typeof AimDialogMode;

export interface AimDialogOptions {
  hasBackdrop?: boolean;
}

export interface AimAlertDialogOptions extends AimDialogOptions {
  title?: string;
  message?: string;
  component?: ComponentType<any>;
  descriptions?: string;
  useConfirm?: boolean;
}

export interface AimProgressDialogOptions extends AimDialogOptions {
  mode: AimProgressModeType;
  message?: string;
  value?: number;
  bufferValue?: number;
}

export const ActionsAlign = {
  start: 'start',
  center: 'center',
  end: 'end'
} as const;

export type ActionsAlignType = keyof typeof ActionsAlign;

export enum AimDialogTheme {
  dark = 'dark',
  white = 'white',
  gray = 'gray'
}

export interface AimDialogConfig<D> extends MatDialogConfig<D> {
  theme?: AimDialogTheme;
}
