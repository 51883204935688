export const DividerWeight = {
  small: 'small',
  large: 'large',
} as const;

export type DividerWeightType = typeof DividerWeight[keyof typeof DividerWeight];

export const DividerColor = {
  primary: 'primary',
  primaryWhite: 'primary-white',
  secondary: 'secondary'
} as const;

export type DividerColorType = typeof DividerColor[keyof typeof DividerColor];
