import { ActionsAlign, ActionsAlignType } from '@aimmo/design-system/aim-dialog/model';
import { Component, Input } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'aim-dialog-actions',
  templateUrl: './aim-dialog-actions.component.html',
  styleUrls: ['./aim-dialog-actions.component.scss'],
  standalone: true,
  imports: [MatDialogModule]
})
export class AimDialogActionsComponent {
  @Input() public actionAlign: ActionsAlignType = ActionsAlign.end;
}
