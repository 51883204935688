import { AimDividerComponent } from '@aimmo/design-system/aim-divider/src';
import { AimIconComponent } from '@aimmo/design-system/aim-icon/src';
import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'aim-dialog-header',
  templateUrl: './aim-dialog-header.component.html',
  styleUrls: ['./aim-dialog-header.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    AimIconComponent,
    AimDividerComponent,
  ],
})
export class AimDialogHeaderComponent {
  @Input() public hideCloseButton = false;
  @Input() public useDivider = false;
  @Input() public useNormalFontWeight = false;
  @Output() private readonly closeButtonClicked = new EventEmitter<void>();
  @Input() private doNotCloseDialog = false;

  constructor(private dialogRef: MatDialogRef<unknown>) {
  }

  public onClose(): void {
    if (this.doNotCloseDialog) {
      this.closeButtonClicked.emit();
      return;
    }
    this.dialogRef.close();
  }
}
