import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AimDividerComponent } from '../../aim-divider/src/aim-divider.component';
import { AimDialogActionsComponent } from '../aim-dialog-actions/aim-dialog-actions.component';
import { AimDialogCloseDirective } from '../directive/aim-dialog-close.directive';
import { AimDialogContentComponent } from '../aim-dialog-content/aim-dialog-content.component';
import { AimDialogHeaderComponent } from '../aim-dialog-header/aim-dialog-header.component';
import { AimDialogService } from './aim-dialog.service';

@NgModule({
  imports: [
    MatDialogModule,
    CommonModule,
    AimDividerComponent,
    AimDialogCloseDirective,
    AimDialogHeaderComponent,
    AimDialogContentComponent,
    AimDialogActionsComponent
  ],
  providers: [
    AimDialogService
  ],
  exports: [
    MatDialogModule,
    AimDialogCloseDirective,
    AimDialogHeaderComponent,
    AimDialogContentComponent,
    AimDialogActionsComponent
  ],
})
export class AimDialogModule {
}
