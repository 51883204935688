import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isNil } from 'lodash-es';
import { AimDialogConfig } from '../model/aim-dialog.model';
import { makeAimDialogConfig } from '../func/aim-dialog.util';

@Injectable({ providedIn: 'root' })
export class AimDialogService {
  constructor(private dialog: MatDialog) {
  }

  public get openDialogs(): MatDialogRef<any, any>[] {
    return this.dialog.openDialogs;
  }

  public open<T, D = any, R = any>(component: any, options?: AimDialogConfig<D>): MatDialogRef<T, R> {
    const dialogConfig = !isNil(options) ? makeAimDialogConfig<D>(options, this.dialog.openDialogs.length, options?.hasBackdrop) : undefined;
    return this.dialog.open<T, D, R>(component, dialogConfig);
  }
}
