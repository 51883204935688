<section class="aim-dialog-header" mat-dialog-title [class.no-gap]="hideCloseButton">
  <strong class="aim-dialog-header__text" [class.font-weight-normal]="useNormalFontWeight">
    <ng-content/>
  </strong>
  <div class="aim-dialog-header__control-wrapper">
    <ng-content select="aim-icon"/>
    <aim-icon (click)="onClose()" *ngIf="!hideCloseButton" class="aim-dialog-header__icon-button" icon="close"/>
  </div>
</section>
<aim-divider *ngIf="useDivider" class="aim-dialog-header__divider"/>
