import { MatDialogConfig } from '@angular/material/dialog';
import {
  AIM_BACKDROP_CLASS,
  AIM_BACKDROP_CLASS_MULTI,
  AIM_PANEL_CLASS
} from '../constants/aim-dialog.constants';
import { AimDialogConfig, AimDialogTheme } from '../model/aim-dialog.model';

export function makeAimDialogConfig<D = any>(options: AimDialogConfig<D>, openDialogsLength = 0, acceptMultiBackdrop = false): MatDialogConfig<D> {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.hasBackdrop = true;
  dialogConfig.restoreFocus = openDialogsLength !== 0;
  dialogConfig.autoFocus = true;
  dialogConfig.delayFocusTrap = true;
  dialogConfig.backdropClass = makeBackdropClass(openDialogsLength !== 0 && !acceptMultiBackdrop);
  dialogConfig.panelClass = [AIM_PANEL_CLASS, options.theme ?? AimDialogTheme.dark];
  Object.entries(options).forEach(([k, v]) => dialogConfig[k] = v);
  return dialogConfig;
}

export function makeBackdropClass(ignoreMultiBackdrop: boolean): string[] {
  const result = [AIM_BACKDROP_CLASS];
  if (ignoreMultiBackdrop) {
    result.push(AIM_BACKDROP_CLASS_MULTI);
  }
  return result;
}
