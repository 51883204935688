import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';
import { DividerColor, DividerColorType, DividerWeight, DividerWeightType } from './model';

@Component({
  selector: 'aim-divider',
  template: '<ng-content/>',
  styleUrls: ['./aim-divider.component.scss'],
  host: {
    class: 'aim-divider',
    '[class.primary]': 'color === dividerColor.primary',
    '[class.secondary]': 'color === dividerColor.secondary',
    '[class.primary-white]': 'color === dividerColor.primaryWhite',
    '[class.large]': 'weight === dividerWeight.large',
    '[class.dialog]': 'isDialog',
  },
  standalone: true,
})
export class AimDividerComponent {
  public readonly dividerColor = DividerColor;
  public readonly dividerWeight = DividerWeight;
  @Input() public readonly weight: DividerWeightType = DividerWeight.small;
  @Input() public readonly color: DividerColorType = DividerColor.primary;
  public isDialog = false;
  private isVertical = false;

  @Input()
  public set aimDialog(_) {
    this.isDialog = true;
  }

  @HostBinding('class.vertical')
  @Input()
  private get vertical(): boolean {
    return this.isVertical;
  }

  private set vertical(value: BooleanInput) {
    this.isVertical = coerceBooleanProperty(value);
  }
}
