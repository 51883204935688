import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'aim-dialog-content',
  templateUrl: './aim-dialog-content.component.html',
  styleUrls: ['./aim-dialog-content.component.scss'],
  standalone: true,
  imports: [MatDialogModule]
})
export class AimDialogContentComponent {
}
